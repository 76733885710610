.block {
    max-width: 1170px;
    margin: 0 auto;
    padding: 80px 0 70px 0;
}

.title {
    color: #000;
    font-size: 30px;
    line-height: 40px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 50px;
}

.titleBlue {
    composes: title;
}

.title > img,
.title > a {
    display: inline-block;
    vertical-align: middle;
}

.titleSelected {
    position: relative;
    color: #565656;
}

.titleSelected:after {
    position: absolute;
    display: block;
    content: "";
    left: 6px;
    right: 6px;
    top: 50px;
    border-bottom: 4px solid #ff1537;
}

.titleBlue .titleSelected:after {
    border-color: #379cdd;
}
