.container {
    width: 100%;
    position: relative;
}

.input {
    width: 100%;
    background: #f5f5f5;
    height: 57px;
    border: 0;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 16px;
    font-weight: 500;
    color: #2c2c2c;
    border-radius: 5px;
}

.input::placeholder {
    color: #a0a0a0;
}

.input:focus {
    outline: none;
    box-shadow: 0 0 1px #82ceff;
}

.suggestionsContainer {
    top: 100%;
    width: 100%;
    margin-top: -5px;
    position: absolute;
    z-index: 1;
    background: #fafafa;
}

.suggestion {
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    color: #606060;
    padding: 12px;
    background: #f5f5f5;
}

.suggestionHighlighted {
    color: #fff;
    background-color: #82ceff;
}

.container .suggestionHighlighted strong {
    color: #fff;
}

.container strong {
    font-weight: 400;
    color: #0a0a14;
}

.spinner {
    position: absolute !important;
    top: 50%;
    margin-top: -12px;
    right: 0 !important;
    z-index: 2;
    margin-right: 0 !important;
}

.spinner:after {
    border: 12px solid !important;
    border-color: #82ceff transparent #82ceff transparent !important;
}

@media (min-width: 667px) {
    .input {
        border-radius: 0;
    }
}

@media (min-width: 768px) {
    .input {
        font-size: 14px;
        font-weight: 400;
    }
}
