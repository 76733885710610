.listing {
    display: block;
    text-decoration: none;
    width: 100%;
    min-width: 250px;
    height: 245px;
}

.listingClient {
    composes: listing;
    height: 275px;
}

.content {
    position: relative;
    height: 200px;
    min-height: 200px;
}

.photoContainer {
    height: 200px;
    position: relative;
}

.photo {
    height: 200px;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.photo > img {
    object-fit: cover;
    width: 100%;
    height: 200px;
}

.photoHidden {
    display: none;
}

.badge {
    position: absolute;
    top: 42px;
    right: 6px;
}

.details {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    height: 36px;
    width: 100%;
}

.details > * {
    width: 31%;
    color: #fff;
    font-size: 12px;
    text-align: center;
    line-height: 36px;
}

.details > div:first-child {
    width: 38%;
}

.details > * > svg {
    height: 16px;
    width: 16px;
    margin-top: -3px;
    margin-right: 3px;
    vertical-align: middle;
    fill: #fff;
}

.details > button {
    line-height: 32px;
    background: transparent;
    border: 0;
    padding: 0;
}

.details > button > svg {
    margin-left: 3px;
    margin-right: 0;
    margin-top: -2px;
}

.details > div:nth-child(2) > svg {
    margin-right: 7px;
}

.info {
    position: relative;
    display: flex;
    height: 45px;
    padding: 6px;
    background-color: #fff;
    border: 1px solid #e5e6e7;
}

.infoClient {
    composes: info;
    height: 75px;
}

.infoContent {
    text-align: right;
    width: 50%;
}

.address > div:first-child {
    color: #434343;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
}

.address > div:last-child {
    color: #434343;
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    white-space: nowrap;
    overflow: hidden;
}

.price {
    position: absolute;
    top: -155px;
    left: 15px;
    font-size: 30px;
    color: #fff;
    font-weight: 600;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 1);
}

.settings {
    position: absolute;
    top: auto;
    bottom: 5px;
    right: 5px;
    background: none;
}

.settings > * {
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    text-align: right;
    line-height: 20px;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 1);
}

.settingsDays {
    background-color: rgba(171, 85, 246, 0.7);
    border-radius: 50%;
    padding: 1px 5px;
}

@media (min-width: 768px) {
    .container {
        flex-direction: row;
    }

    .listing {
        height: 275px;
    }

    .info {
        height: 75px;
    }

    .price {
        position: relative;
        top: unset;
        left: unset;
        font-size: 20px;
        color: #2a2a2a;
        margin-top: 8px;
        font-weight: 700;
        text-shadow: none;
    }
}

@media (max-width: 400px) {
    .price {
        font-size: 22px;
    }
}
