.agent {
    width: 50%;
    position: relative;
}

.inner {
    position: absolute;
    top: -52px;
    left: 8px;
    text-align: center;
}

.title {
    margin-bottom: 3px;
    font-size: 11px;
    color: #fff;
    font-weight: 800;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);
}

.name,
.phone {
    font-size: 14px;
    color: #384042;
    line-height: 17px;
    font-weight: 600;
    height: 17px;
    overflow: hidden;
}

.avatar img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 2px solid #05c1b2;
    background: #fff;
}
