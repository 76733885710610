.agent {
    width: 50%;
    line-height: 16px;
}

.title {
    color: #2c2c2c;
    font-size: 12px;
    font-weight: 600;
}

.name {
    color: #575757;
    font-size: 12px;
    overflow: hidden;
    font-weight: 500;
}
