.header {
    position: relative;
    margin: 10px auto 0 auto;
    padding: 0 20px 0 20px;
    display: flex;
    align-items: center;
}

.loginBtn {
    color: #fff;
    text-decoration: none;
    border: 2px solid #fff;
    font-size: 15px;
    font-weight: 500;
    border-radius: 3px;
    padding: 11px 14px;
}

.loginBtn > svg {
    vertical-align: middle;
}

.menu {
    flex: 1;
}

.auth {
    text-align: right;
    position: absolute;
    top: 0;
    right: 20px;
}

@media (min-width: 990px) {
    .header {
        padding-right: 50px;
        text-align: center;
    }
}
