.neighborhoods {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
}

.item {
    margin: 30px auto;
    text-decoration: none;
}

.img {
    background: #ababab;
    position: relative;
    height: calc(100% * 0.8);
}

.img > img {
    display: block;
    aspect-ratio: 338/344;
    width: 100%;
    height: 100%;
}

.footer {
    background: #3c95da;
    color: #fff;
    padding: 22px 10px 22px 20px;
    display: flex;
    align-items: center;
}

.name {
    flex: 1;
}

.nameContent {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 6px;
}

.state {
    font-size: 20px;
    font-weight: 500;
}

.listings {
    text-align: right;
    font-size: 15px;
    font-weight: 500;
}

@media (min-width: 768px) {
    .neighborhoods {
        flex-direction: row;
        padding: 0 10px;
    }

    .item {
        margin: 20px 0 20px 0;
        flex-basis: 30%;
    }
}

@media (min-width: 1100px) {
    .item {
        flex-basis: 19%;
    }
}
