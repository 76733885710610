.blog {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
}

.post {
    margin: 30px auto;
    width: 264px;
}

.img {
    background: #ababab;
    height: 220px;
}

.footer {
    padding: 22px 20px;
    border: 1px solid #eaeaea;
}

.title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 7px;
}

.date {
    font-size: 12px;
    color: #a5a5a5;
}

@media (min-width: 768px) {
    .blog {
        flex-direction: row;
        padding: 0 10px;
    }

    .post {
        margin: 0 20px 20px 0;
    }
}
