.form {
    text-align: center;
    max-width: 500px;
    margin: 0 auto;
    width: 100%;
    position: relative;
}

.formBlue {
    composes: form;
}

.hiddenLabels {
    position: absolute;
    margin-left: -9999px;
}

.form > * {
    margin-bottom: 13px;
}

.submit {
    background-color: #ff0a2e;
    min-width: 110px;
    height: 57px;
    color: #fff;
    font-size: 16px;
    border: 0;
    border-radius: 5px;
    cursor: pointer;
}

.submit:focus {
    outline: none;
}

.formBlue .submit {
    background-color: #379cdd;
}

.form > *:nth-child(1),
.form > *:nth-child(n + 3) {
    display: none;
}

.form > .submit {
    display: inline-block;
    width: 100%;
}

.searchError {
    display: block !important;
    border-right: 0 !important;
    color: #fff;
    text-shadow: 1px 1px rgba(1, 1, 1, 0.3);
    font-weight: 500;
    line-height: 19px;
}

@media (min-width: 667px) {
    .form {
        display: flex;
        max-width: none;
        width: auto;
        margin-left: 10px;
        margin-right: 10px;
    }

    .form > * {
        min-width: 130px;
        border-right: 1px solid #e2e2e2;
    }

    .form > *:nth-last-child(-n + 2) {
        border-right: 0;
    }

    .form > *:nth-child(1) {
        display: block;
    }

    .form > *:first-child > div {
        border-radius: 5px 0 0 5px;
    }

    .submit {
        border-radius: 0 5px 5px 0;
    }

    .form > .submit {
        flex-basis: 130px;
    }

    .searchError {
        position: absolute;
        top: 65px;
    }
}

@media (min-width: 1024px) {
    .form > *:nth-child(n + 3) {
        display: block;
    }
}

@media (min-width: 1170px) {
    .form {
        margin-left: 0;
        margin-right: 0;
    }
}
