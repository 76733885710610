.badge {
    display: inline-block;
    font-size: 11px;
    font-weight: 600;
    line-height: 19px;
    color: #fff;
    padding: 0 8px;
    border-radius: 3px;
}

.badge-red,
.badge-RED {
    composes: badge;
    background-color: var(--badge-color-RED);
}

.badge-yellow,
.badge-YELLOW {
    composes: badge;
    background-color: var(--badge-color-YELLOW);
}

.badge-pink,
.badge-PINK {
    composes: badge;
    background-color: var(--badge-color-PINK);
}

.badge-blue,
.badge-BLUE {
    composes: badge;
    background-color: var(--badge-color-BLUE);
}

.badge-black,
.badge-BLACK {
    composes: badge;
    background-color: var(--badge-color-BLACK);
}

.badge-orange,
.badge-ORANGE {
    composes: badge;
    background-color: var(--badge-color-ORANGE);
}

.badge-green,
.badge-GREEN {
    composes: badge;
    background-color: var(--badge-color-GREEN);
}
