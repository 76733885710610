.block {
    padding: 120px 10px;
    background: #23395a;
    color: #fff;
}

.blockWhite {
    composes: block;
    background: #fff;
    padding: 46px 10px;
}

.container {
    max-width: 1170px;
    margin: 0 auto;
    position: relative;
}

.title {
    font-size: 36px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
}

.text {
    max-width: 550px;
    margin: 0 auto;
    line-height: 23px;
    font-size: 15px;
}

.button {
    display: block;
    border: 1px solid #fff;
    border-radius: 3px;
    font-size: 14px;
    max-width: 160px;
    padding: 11px 40px;
    color: #fff;
    text-decoration: none;
    margin: 20px auto 0 auto;
}

@media (min-width: 990px) {
    .button {
        position: absolute;
        top: 50px;
        right: 10px;
        margin-top: 0;
    }
}
