.jumbotron {
    height: 100vh;
    background: url("/static/img/index-sale-bg3.jpg") no-repeat center center;
    background-size: cover;
    padding-top: 20px;
}

.jumbotronBlue {
    composes: jumbotron;
    background: url("/static/img/index-rent-bg.jpg") no-repeat center center;
    background-size: cover;
}

.inner {
    padding: 0 20px;
    max-width: 1170px;
    width: 100%;
    position: absolute;
    top: 50%;
    margin: -200px auto 0;
}

.logo {
    padding: 0 0;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 25px;
    height: 50px;
}

.logo img {
    margin: 0 auto;
}

.links {
    margin: 40px 0;
    text-align: center;
}

.link {
    text-decoration: none;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    text-shadow: 0px 7px 15px rgba(0, 0, 0, 0.4);
}

.linkRed {
    composes: link;
    margin-right: 35px;
}

.linkRedActive {
    composes: linkRed;
    border-bottom: 3px solid #ea353a;
}

.linkBlue {
    composes: link;
}

.linkBlueActive {
    composes: linkBlue;
    border-bottom: 3px solid #379cdd;
}

.slideDown {
    position: absolute;
    bottom: 10px;
    left: 50%;
    margin-left: -15px;
}

.slideDown > svg {
    fill: #fff;
    height: 36px;
    width: 36px;
}

@media (min-width: 375px) {
    .logo {
        height: 56px;
    }
}

@media (min-width: 414px) {
    .logo {
        height: 56px;
    }
}

@media (min-width: 667px) {
    .links {
        display: none;
    }
}

@media (min-width: 720px) {
    .logo {
        height: 116px;
    }
}

@media (min-width: 768px) {
    .jumbotron {
        height: 670px;
    }

    .inner {
        position: relative;
        margin: 0 auto;
        top: 0;
        padding: 0;
    }

    .logo {
        margin-top: 150px;
        margin-bottom: 115px;
    }

    .slideDown {
        display: none;
    }
}
