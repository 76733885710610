.container {
}

.listings {
    display: grid;
    grid-template-columns: 1fr;
    gap: 14px;
    margin-bottom: 7px;
}

.buttonContainer {
    text-align: center;
}

.button {
    display: inline-block;
    text-align: center;
    margin: 10px auto 0 auto;
    background: #32afe4;
    color: #fff;
    text-decoration: none;
    font-weight: 600;
    font-size: 13px;
    padding: 11px 20px;
    border-radius: 2px;
}

@media (min-width: 560px) {
    .listings {
        grid-template-columns: 1fr 1fr;
    }
}

@media (min-width: 768px) {
    .listings {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media (min-width: 1300px) {
    .listings {
        justify-content: center;
        grid-template-columns: repeat(6, 1fr);
    }

    .listings > * {
        max-width: 350px;
    }
}
