.spinnerContainer {
    z-index: 2;
    position: absolute;
    width: 100%;
    height: 100%;
    min-height: 250px;
    background-color: rgba(255, 255, 255, 0.7);
}

.loading {
    position: relative;
    top: 50%;
    left: 50%;
    margin-top: -70px;
    margin-left: -40px;
}

.loadingText {
    margin-top: 30px;
    font-weight: 600;
    color: #ff6d6d;
    text-shadow: 1px 1px rgba(1, 1, 1, 0.5);
}

.spinner,
.spinnerButton {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
    margin-right: 35px;
}

.spinner:after,
.spinnerButton:after {
    content: " ";
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    box-sizing: border-box;
    border: 32px solid;
    border-color: #ff6d6d transparent #ff6d6d transparent;
    animation: spinner 1.2s infinite;
}

.spinnerButton {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.spinnerButton:after {
    margin: 4px;
    border: 8px solid;
    border-color: #ff6d6d transparent #ff6d6d transparent;
}

@keyframes spinner {
    0% {
        transform: rotate(0);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
        transform: rotate(900deg);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
        transform: rotate(1800deg);
    }
}
